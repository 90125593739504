import Head from "next/head"
import { useRouter } from "next/router"

const HrefLang = () => {
  const router = useRouter()
  const hreflangTags = generateHreflangTags(router.asPath)
  const currentURL = `https://${
    router.locale === "zh-TW" ? "tw." : ""
  }threadslytics.com${router.asPath}`

  return (
    <Head>
      {hreflangTags.map((tag) => (
        <link
          key={tag.hreflang}
          rel="alternate"
          href={tag.href}
          hreflang={tag.hreflang}
        />
      ))}
      <link rel="canonical" href={currentURL} />
    </Head>
  )
}

const generateHreflangTags = (currentPath) => {
  const baseUrls = {
    en: "https://threadslytics.com",
    zh: "https://tw.threadslytics.com",
  }

  return [
    { href: `${baseUrls.zh}${currentPath}`, hreflang: "zh-tw" },
    { href: `${baseUrls.zh}${currentPath}`, hreflang: "zh-hk" },
    { href: `${baseUrls.en}${currentPath}`, hreflang: "en" },
    { href: `${baseUrls.en}${currentPath}`, hreflang: "x-default" },
  ]
}

export default HrefLang
